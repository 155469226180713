import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/home/home.vue'
// import About from '@/views/about/about.vue'
// import DeviceDetail from '@/views/device-detail/device-detail.vue'
// import Solution from '@/views/solution/solution.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    // component: About,
    component: () => import('@/views/about/about.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/device-detail/:id',
    name: 'device-detail',
    // component: DeviceDetail,
    component: () => import('@/views/device-detail/device-detail.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/solution',
    name: 'solution',
    // component: Solution,
    component: () => import('@/views/solution/index/index.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/solution/:id',
    name: 'solution-detail',
    component: () => import('@/views/solution/detail/solution.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/software',
    name: 'software',
    component: () => import('@/views/software/index/index.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/news-list',
    name: 'news-list',
    component: () => import('@/views/news-list/news-list.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/news-detail/:id',
    name: 'news-detail',
    component: () => import('@/views/news-detail/news-detail.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/news-console',
    name: 'news-console',
    component: () => import('@/views/news-console/news-console.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/device-tail/:id',
    name: 'device-tail',
    component: () => import('@/views/device-detail/device-detail.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/software/:id',
    name: 'software-detail',
    component: () => import('@/views/software/detail/detail.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import('@/views/cases/index/index.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
  {
    path: '/cases/:id',
    name: 'cases-detail',
    component: () => import('@/views/cases/detail/detail.vue'),
    meta: {
      nav: true,
      footer: true,
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

export default router
